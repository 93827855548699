import { UploadImage } from "components";
import { TicketSubject } from "interfaces";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Icon, Modal, Select, TextArea } from "semantic-ui-react";
import { api, utils } from "services";

export default function CreateTicket() {
  const nav = useNavigate();
  const [data, setData] = useState<any>({});
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  let subjectItems = useMemo(() => {
    let rs: any = [];
    for (var i in TicketSubject) {
      if (!isNaN(Number(i))) {
        rs.push({ key: i, value: Number(i), text: TicketSubject[i] });
      }
    }
    return rs;
  }, []);
  function handleChange(name: string, value: any) {
    let tmp = _.cloneDeep(data);
    tmp[name] = value;
    setData(tmp);
  }
  function onRemoveImage(index: number) {
    let tmp = _.cloneDeep(data?.attachs || []);
    tmp.splice(index, 1);
    handleChange("attachs", tmp);
  }
  async function submitTicket() {
    try {
      setLoading(true);
      if (!(data.content && data.subject !== undefined)) {
        setError("Please enter ticket content and subject");
        return;
      }
      setError("");
      await api.post("/support-ticket/create-ticket", data);
      setOpenSuccess(true);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div>
      <Modal open={openSuccess} size="small">
        <Modal.Content>
          <div className="text-center pb-10">
            <img src="img/success.png" className="w-28 h-28 mx-auto" />
            <p className="font-semibold text-4xl text-center mt-8">
              Submit success
            </p>
            <p className="text-center mt-8 text-xl">
              Your support ticket has been submitted successfully. Please wait
              for our response whithin 48 hours!
            </p>
            <button
              className="main-gradient p-2 w-48 rounded-md text-white mx-auto text-xl mt-16 block"
              onClick={() => {
                setOpenSuccess(false);
                nav(-1);
              }}
            >
              Close
            </button>
          </div>
        </Modal.Content>
      </Modal>
      <div>
        <span
          className="font-semibold text-xl cursor-pointer"
          onClick={() => {
            nav(-1);
          }}
        >
          <Icon name="angle left" /> Create new ticket
        </span>
      </div>
      <div className="rounded-md overflow-hidden mt-4">
        <div className="flex justify-end items-center p-3 bg-[#E2E3E8]">
          <div className="flex gap-2 items-center">
            <p>Subject</p>
            <Select
              placeholder="Select subject"
              onChange={(evt, { value }) => {
                handleChange("subject", value);
              }}
              options={subjectItems}
              defaultValue={data.subject}
            />
          </div>
        </div>
        <div className="p-3 bg-white min-h-[450px]">
          {!utils.inWorkingTime() && (
            <p className="text-red-600">Working time: 2h UTC to 9h UTC.</p>
          )}

          <TextArea
            className="w-full border-[#DFE0E6] border rounded-md p-4"
            placeholder="Type here..."
            rows={6}
            onChange={(evt: any) => {
              handleChange("content", evt.target.value);
            }}
          />
          {data?.attachs?.length > 0 && (
            <div className="flex flex-wrap gap-2 justify-end mt-4">
              {data?.attachs.map((url: string, index: number) => (
                <div
                  key={index}
                  className="w-56 h-36 rounded-md border border-gray-200 p-2 relative"
                >
                  <div
                    className="p-2 error-gradient rounded-md text-white w-10 h-10 text-center cursor-pointer absolute top-2 left-2"
                    onClick={() => {
                      onRemoveImage(index);
                    }}
                  >
                    <Icon className="trash" />
                  </div>
                  <img src={url} className="w-full h-full object-contain" />
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-end mt-4">
            <UploadImage
              setUploading={setUploading}
              onChange={(url: any) => {
                let tmp = _.cloneDeep(data.attachs || []);
                tmp.push(url);
                handleChange("attachs", tmp);
              }}
            >
              <button
                disabled={uploading}
                className="bg-[#44240E] rounded-md py-2 px-4 text-white"
              >
                {uploading ? "Uploading..." : "Upload your attach files"}
              </button>
            </UploadImage>
          </div>
        </div>
        {error !== "" && (
          <p className="text-red-600 font-semibold text-center mt-4">{error}</p>
        )}
        <div className="flex justify-center mt-4">
          <button
            className="main-gradient text-white rounded-md px-8 py-4"
            onClick={submitTicket}
          >
            {loading ? "Submiting..." : "Submit your Ticket"}
          </button>
        </div>
      </div>
    </div>
  );
}
