import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/utc"));

function formatHostUrl(hostapi: string) {
  if (hostapi[hostapi.length - 1] === "/") {
    return hostapi.slice(0, -1);
  }
  return hostapi;
}

function inWorkingTime() {
  if ([0, 6].includes(dayjs().day())) {
    return false;
  }
  //@ts-ignore
  let fromTime = dayjs().utc().startOf("day").add(2, "hour").toDate();
  //@ts-ignore
  let toTime = dayjs().utc().startOf("day").add(9, "hour").toDate();
  return fromTime < new Date() && new Date() < toTime;
}

export const toHex = (num: any): string => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

const utils = {
  toHex,
  inWorkingTime,
  formatHostUrl,
};
export default utils;
