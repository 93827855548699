import { DatePicker } from "components";
import dayjs from "dayjs";
import { TicketStatus, TicketSubject } from "interfaces";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Header, Pagination, Select, Table } from "semantic-ui-react";
import { api } from "services";
const colors = ["red", "green"];
const PAGE_SIZE = 10;
export default function YourTicket() {
  const nav = useNavigate();
  const [filter, setFilter] = useState<any>({ skip: 0, limit: 20 });
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  let subjectItems = useMemo(() => {
    let rs: any = [];
    rs.push({ key: -1, value: -1, text: "All" });
    for (var i in TicketSubject) {
      if (!isNaN(Number(i))) {
        rs.push({ key: i, value: Number(i), text: TicketSubject[i] });
      }
    }
    return rs;
  }, []);
  function handleChange(name: string, value: any) {
    let tmp = _.cloneDeep(filter);
    tmp[name] = value;
    setFilter(tmp);
  }
  useEffect(() => {
    async function loadItems() {
      setLoading(true);
      let tmp = _.cloneDeep(filter);
      if (tmp.date_from) {
        tmp.date_from = tmp.date_from.getTime();
      }
      if (tmp.date_to) {
        tmp.date_to = tmp.date_to.getTime();
      }
      tmp.limit = PAGE_SIZE;
      tmp.skip = PAGE_SIZE * (currentPage - 1);
      let { count, rows } = await api.post(
        "/support-ticket/get-my-ticket",
        tmp
      );
      setData(rows);
      setCount(count);
      setLoading(false);
    }
    loadItems();
  }, [filter, currentPage]);
  return (
    <div>
      <div className="flex justify-between">
        <p className="font-semibold text-xl">Your tickets</p>
        <div className="flex gap-2">
          <Select
            clearable
            placeholder="Select subject"
            onChange={(evt, { value }) => {
              if (value === "") {
                handleChange("subject", undefined);
              } else {
                handleChange("subject", value);
              }
            }}
            options={subjectItems}
            // multiple
            defaultValue={filter.subject}
          />
          <DatePicker
            placeholderText="Select date"
            className="p-2 px-5 rounded-md border border-gray-200"
            selectsRange={true}
            startDate={filter.date_from}
            endDate={filter.date_to}
            onChange={(update: any) => {
              let [dateFrom, dateTo] = update;
              let tmp = _.cloneDeep(filter);
              tmp.date_from = dateFrom;
              tmp.date_to = dateTo;
              setFilter(tmp);
            }}
            isClearable
            // withPortal
          />
          <a href="#/create-ticket">
            <button className="main-gradient text-white p-2 rounded-md w-36">
              Create New
            </button>
          </a>
        </div>
      </div>
      <div className="mt-4">
        <Table className="w-full" selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Ticket number</Table.HeaderCell>
              <Table.HeaderCell>Subject</Table.HeaderCell>
              <Table.HeaderCell>Date create</Table.HeaderCell>
              <Table.HeaderCell>Last update</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Is Response</Table.HeaderCell>
              <Table.HeaderCell>View</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.length > 0 &&
              data.map((ticket: any, index: number) => (
                <Table.Row className="cursor-pointer">
                  <Table.Cell>{ticket.id}</Table.Cell>
                  <Table.Cell>{TicketSubject[ticket.subject]}</Table.Cell>
                  <Table.Cell>
                    {dayjs(ticket.created_at).format("YYYY-MM-DD HH:mm:ss")}
                  </Table.Cell>
                  <Table.Cell>
                    {dayjs(ticket.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                  </Table.Cell>
                  <Table.Cell>
                    {/* @ts-ignore */}
                    <Header as="h4" color={colors[ticket.status]}>
                      {TicketStatus[ticket.status]}
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Header as="h4">
                      {ticket.is_response ? (
                        <span className="text-green-400">Yes</span>
                      ) : (
                        <span className="text-red-400">No</span>
                      )}
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      content="View"
                      size="small"
                      as="a"
                      href={`#/ticket-detail/${ticket.id}`}
                      color="teal"
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            <Table.Footer>
              <Table.Row></Table.Row>
            </Table.Footer>
          </Table.Body>
        </Table>
        <div className="w-full flex justify-end">
          <Pagination
            activePage={currentPage}
            boundaryRange={6}
            onPageChange={(e: any, { activePage }) => {
              setCurrentPage(Number(activePage));
            }}
            size="mini"
            totalPages={Math.ceil(count / PAGE_SIZE)}
            firstItem={null}
            lastItem={null}
          />
        </div>
      </div>
    </div>
  );
}
