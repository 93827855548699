import axios from "axios";
import Config, { SSO_META } from "config";
import { store } from "reducer/store";
import { bulkSetUserInfo, clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import { LoginType } from "interfaces";
import ui from "./ui";
import utils from "./utils";
import { setLoginType } from "reducer/logintype.slice";
import { clearSSOMeta, setSSOMeta } from "reducer/ssometa.slice";

//@ts-ignore
const Web3 = window.Web3;
//@ts-ignore
const ethereum = window.ethereum;
const web3 = new Web3(Web3.givenProvider);

// config api method
async function post(url: string, data: any): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.ApiHost)}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    body: JSON.stringify(data),
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      store.dispatch(clearUserInfo());
      store.dispatch(clearAuthInfos());
      ui.alertFailed("Your session is expired !", "Please sign in again !");
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function get(url: string): Promise<any> {
  let rs = await fetch(`${utils.formatHostUrl(Config.ApiHost)}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  switch (rs.status) {
    case 200:
      let tmp = await rs.json();
      return tmp;
    case 403:
      sessionStorage.clear();
      localStorage.clear();
      throw new Error("forbidden");
    default:
      let err = await rs.json();
      throw err;
  }
}
async function postFormData(url: string, data: any, cb?: Function): Promise<any> {
  var formdata = new FormData();
  for (var i in data) {
    formdata.append(i, data[i]);
  }
  let tmp: any = await axios.request({
    method: "post",
    url: `${utils.formatHostUrl(Config.ApiHost)}${url}`,
    data: formdata,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      Accept: "multipart/form-data",
    },
    onUploadProgress: (p) => {
      if (cb) cb(p);
    },
  });
  if (tmp.code === "forbidden") {
    window.location.href = "/auth";
    throw new Error("forbidden");
  }
  return tmp.data;
}

function getChainId(type: LoginType) {
  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      return SSO_META().network[LoginType[type]].chainId;
    case LoginType.Terra:
      return "pisco-1";
  }
}

async function changeNetwork(type: LoginType) {
  let chainData = SSO_META().network[LoginType[type]]
  try {
    console.log(chainData?.chainId)
    // @ts-ignore
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{chainId: chainData?.chainId}],
    });
  } catch (switchError: any) {
    console.log({switchError})
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      console.log("Add net work");
      // @ts-ignore
      await window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [chainData],
        })
        .catch((error: any) => {
          console.log(error);
          throw error;
        });
    }
  }
}

async function checkNetwork(type: LoginType) {
  if (Config.AntiWalletAuth.includes(type)) return;
  // @ts-ignore
  const currentNetwork = await window.ethereum.request({
    method: "eth_chainId",
  });
  if (getChainId(type) !== currentNetwork) {
    await changeNetwork(type);
    // @ts-ignore
    const changedNetwork = await window.ethereum.request({ method: "eth_chainId" });
    if (getChainId(type) !== changedNetwork) {
      throw new Error("failed to switch network");
    }
    store.dispatch(setLoginType(type));
  }
}

async function login(type: LoginType, social_access_token?: string, email?: string, password?: string) {
  sessionStorage.clear();
  let nonce = await getNonce();
  await checkNetwork(type);
  let sign, account;
  switch (type) {
    case LoginType.BSC:
    case LoginType.Avalanche:
    case LoginType.Okex:
      account = await ethereum.request({ method: "eth_requestAccounts" });
      sign = await web3.eth.personal.sign(nonce, account[0], "publicpassword");
      break;
  }
  let { customerInfo, token } = await api.post("/customer/login-support-customer", {
    sign,
    nonce,
    type,
    social_access_token,
    email,
    password,
  });
  let userInfo = {
    id: customerInfo.A,
    name: customerInfo.D,
  };
  await api.setStateLogin(token, userInfo);
}

async function setStateLogin(token: string, customerInfo: any) {
  sessionStorage.setItem("token", token);
  store.dispatch(bulkSetUserInfo(customerInfo));
}

async function getNonce() {
  let rs = await api.post("/nonce/create-nonce", {});
  return rs.nonce;
}

async function uploadImage(image: any): Promise<any> {
  return postFormData(`${utils.formatHostUrl(Config.ApiHost)}/file/upload-image`, { image });
}
async function getSSOMeta() {
  const ssoMeta = await get("/customer/get-SSO-meta");
  store.dispatch(clearSSOMeta());
  store.dispatch(setSSOMeta(ssoMeta));
}

// export
const api = {
  getSSOMeta,
  setStateLogin,
  getNonce,
  post,
  postFormData,
  uploadImage,
  login,
};
export default api;
