export interface EnumEntity {
  name: string;
  items: EnumItem[];
  value_type: "string" | "number";
}
export const AllColors = [
  "red",
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
  "brown",
  "grey",
  "black",
];
export interface FormEntity {
  name: string;
  label: string;
  api: number;
  buttons: Button[];
  controls: FormControl[];
}

export enum Size {
  Small,
  Medium,
  Big,
  Huge,
}
export enum LoginType {
  BSC,
  Terra,
  Username,
  Avalanche,
  Facebook,
  Google,
  GoogleMobile,
  SavedToken,
  Okex,
  Farmer,
}
export enum AuthState {
  Metamask,
  Terra,
  Email,
  Farmer,
}
export enum CustomerRole {
  Owner,
  Farmer,
}
export enum CellDisplay {
  Text = "text",
  Number = "number",
  Date = "date",
  Image = "image",
  Switch = "switch",
  Enum = "enum",
  Entity = "entity",
  JSON = "json",
  Entities = "entities",
  ArrayString = "arraystring",
  File = "file",
}
export interface SearchItem {
  label: string;
  field: string;
  type: "text" | "number" | "date";
  control?: "input" | "enum";
  isRange: boolean;
  enum?: string;
  multiple?: boolean;
}
export interface GridColumn {
  label: string;
  field: string;
  sortField?: string;
  display: CellDisplay;
  api?: string;
  sorter?: boolean;
  defaultSortOrder?: string;
  enumName?: string;
  fixed?: string;
  width: number;
}
export interface Button {
  label: string;
  pageMode?: string;
  classes?: string;
  icon?: string;
  showInColumn?: string;
  action: "api" | "redirect";
  api?: string;
  redirectUrl?: string;
  redirectUrlEmbed?: any;
  embedData?: object;
  popupData?: object;
  confirmText?: string;
  backOnAction?: boolean;
  position?: string;
  successMessage?: string;
  failMessage?: string;
  disableReload?: boolean;
  hideExpression?: object;
}
export enum SchemaDataType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Array = "array",
  Object = "object",
}
export enum SchemaControl {
  Enum = "enum",
  Text = "text",
  TextArea = "textarea",
  Image = "image",
  ArrayImage = "arrayimage",
  Location = "location",
  RichText = "richtext",
  Password = "password",
  Captcha = "captcha",
  Label = "label",
  Step = "step",
  Entity = "entity",
  JSON = "json",
  Date = "date",
  TextArray = "textarray",
  Divider = "divider",
  CheckList = "checklist",
  Schema = "schema",
  Teacher = "teacher",
  Upload = "upload",
}

export interface FormControl {
  label: string;
  field: string;
  dataType: SchemaDataType;
  caption?: string;
  required: boolean;
  disabled: boolean;
  control: SchemaControl;
  gridName: string;
  schemaName: string;
  enum: string;
  hideExpression?: object;
  requireExpression?: object;
  api: string;
  multiple?: boolean;
}
export enum Language {
  English,
  Chinese,
}
export interface EnumItem {
  value: string;
  label: string;
  classes?: string;
}
export interface GridEntity {
  name: string;
  label: string;
  api: string;
  buttons: Button[];
  columns: GridColumn[];
  filter: SearchItem[];
}
export enum YesNoEnum {
  No,
  Yes,
}
export interface UserEntity {
  id: number;
  name: string;
  role: UserRole;
}
export interface AuthEntity {
  A: number; // "id",
  B: number; // "customer_id",
  C: string; // "identity",
  D: number; // "type",
  E: number; // "role",
  F: string; // "password",
  G: boolean; // "is2FARequired",
  H: string; // password_last_update
  I: boolean; // is_2fa_enabled
  mstr_wallet: number;
  mag_wallet: number;
}

export interface CustomerTransaction {
  A: number; // id,
  B: number; // customer_i,d,
  C: Token; // token_type,
  D: number; // value
  E: Date; // claim_at
  F: boolean; // is_claim
  G: string; //wallet address
  H: Date; // create_at
}

export enum ButtonColor {
  "red",
  "orange",
  "blue",
  "yellow",
  "gray",
  "purple",
  "brown",
}

export enum ButtonVariant {
  "primary",
  "outline",
}

export enum GameItem {
  "mag",
  "mstr",
  "evo-item",
}

export enum RewardType {
  FOOD,
  EVOLUTION_ITEM,
  MAG,
  EXP,
  SOULCORE,
  MSTR,
  PLOT,
  BOX,
}
export interface RewardEntity {
  reward_type: RewardType;
  reward_quantity: number;
  plot_type: PlotType;
  race: MongenRace;
  rarity: Rarity;
  rate: number;
}

export enum PlotType {
  LandCore,
  Pasture,
  Breeding,
  Hatching,
  Production,
  Storage,
  Barracks,
  Defenses,
  Decor,
  All,
}

export enum Token {
  MAG,
  MSTR,
}

export enum Rarity {
  Common,
  Uncommon,
  Rare,
  Epic,
  Legendary,
}
export enum MongenRace {
  Beast,
  Tectos,
  Mystic,
  Celest,
  Chaos,
  All,
}
export interface GasPrice {
  denom: string;
  price: number;
}

export enum NFTType {
  Mongen,
  SoulCore,
  Land,
  Plot,
  EvolutionItem,
  NormalStone,
  RerollStone,
  EliteStone,
  NormalRuneStone,
  RerollRuneStone,
  EliteRuneStone,
  TicketCO,
  TicketUC,
  TicketRA,
  TicketEP,
  TicketLE,
}

export enum Chain {
  BSC,
  Terra,
}
export enum TicketSubject {
  Account,
  Gameplay,
  Marketplace,
  Bridge,
  DAO,
  Guild,
  PortalGame,
  Others,
}
export enum TicketStatus {
  Unresolved,
  Resolved,
}
export enum TicketPriority {
  Low,
  Medium,
  High,
  Highest,
}
export interface TicketComment {
  name: string;
  content: string;
  attachs: string[];
  createdDate: Date;
  creator_id: number;
  is_admin: boolean;
}
export enum UserRole {
  Customer,
  Admin,
}
export interface UserInfo {
  id: number;
  name: string;
  role: UserRole;
}


export type SSO_TYPE = {
  DAOLink: string;
  GuideLink: string;
  DaoGuildLink: string;
  LiteLink: string;
  GameLink: string;
  SupportLink: string;
  WhitePaperLink: string;
  TerraChainId: string;
  BattleLink: string;
  PortalLink: string;
  BridgeLink: string;
  GuildLink: string;
  EventLink: string;
  MarketLink: string;
  DocsLink: string;
  MonstvLink: string;
  MonsterraverseLink: string;
  LandingPage: string;
  MetamaskExtension: string;
  TerraExtension: string;
  contract: any;
  network: any;
  GOOGLE_CLIENT_ID: string;
  FACEBOOK_APP_ID: string;
  CAPTCHA_CLIENT_ID: string;
  S3Host: string;
  price: {
    BNB: string;
    AVAX: string;
    LUNA: string;
    UST: string;
    OKT: string; 
  }
}