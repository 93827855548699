export default function Account() {
  return (
    <div className="w-full p-5 max-w-[800px] mx-auto" id="account">
      <p className="text-2xl font-bold text-center">
        Monsterra Mobile(Open Beta): <br />
        Facebook and Email Login Guideline on GG Play. Let's Try!
      </p>
      <p className="italic">
        Make your login easier with our super quick guidelines!
      </p>
      <img src="account/1.webp" />
      <p>
        You are suffering from the obstacles of logging into the Monsterra game
        using your Google account? No worries. We've backed you up with super
        easy login via your Facebook and Email. Let's check it out NOW!
      </p>

      <p>
        <b>Step 1:</b>
      </p>

      <p>
        Log into{" "}
        <b>
          <a target="_blank" href="https://dao.monsterra.io">
            Monsterra DAO Treasury
          </a>
        </b>{" "}
        using your Google account (on web)
      </p>
      <img src="account/2.png" />

      <p>Go to “My Account” section</p>
      <img src="account/3.png" />
      <p>
        Select 1 out of 2 or both login options: Email and/or Facebook. If you
        connect your account using 2 options, please make sure to do all the
        following instructions in turn.
      </p>
      <img src="account/4.png" />
      <p>
        <b>Step 2:</b>{" "}
      </p>
      <p>Proceed with the detailed steps for each login option</p>

      <p>
        <b>1. Connect to Monsterra Account Using Email</b>
      </p>
      <p>
        Click into the “Email” section =&gt; Fill in your “Email' address =&gt;
        Click “Save”
      </p>
      <img src="account/5.png" />

      <p>
        Once clicking on “Save” button, you will receive the following pop-up:
      </p>
      <img src="account/6.png" />

      <p>
        Open your email to confirm the process. The sent email will be displayed
        as follows:
      </p>
      <img src="account/7.png" />

      <p>
        Click on “Confirm” to redirect to Monsterra DAO and change your
        password.
      </p>
      <img src="account/8.png" />

      <p>
        Once the account is successfully connected using Email, users will
        receive the following notification.
      </p>
      <img src="account/9.png" />

      <p>
        <b>2. Connect to Monsterra Account Using Facebook</b>
      </p>
      <p>
        Case 1: If users have already logged in their Facebook account on PC
      </p>

      <p>
        Select the “Facebook” option =&gt; The account will be automatically
        connected
      </p>
      <p>
        Case 2: If users haven't already logged in their Facebook account on PC
      </p>

      <p>Select the “Facebook” option =&gt; Login Facebook account</p>
      <img src="account/10.png" />

      <p>
        Once logged into your Facebook account, you will receive the following
        notification.
      </p>
      <img src="account/11.png" />

      <p>
        Go back to your Monsterra Mobile and log into the game using your
        Facebook
      </p>
      <p>
        Hope that you find this guideline useful. Any further concerns, please
        contact us via our{" "}
        <b>
          <a href="https://t.me/+Tk-7lAEoy70zMDg1" target="_blank">
            Telegram Chat Group.
          </a>
        </b>
      </p>

      <p>
        <b>About Monsterra</b>
      </p>
      <p>
        Monsterra is a revolutionary multi-chain NFT game with a
        free-to-play-and-earn mechanism on BNB, Avalanche, and OKX networks.
      </p>
      <img src="account/12.webp" />

      <p>
        The game set foot in a fictional world and revolves around farming,
        property building, and battling other lands with the magical creatures
        named Mongen. The revolutionary design of Monstera is a combination of
        free-to-play and free-to-earn models, which allows millions of gaming
        enthusiasts to enjoy fun and have a high-profit stream with no initial
        investment.
      </p>

      <p>
        If you have any questions, contact us via email or social media. We will
        settle any concerns you may have.
      </p>
    </div>
  );
}
