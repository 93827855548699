import { UserInfo } from "interfaces";
import { Header, Label } from "semantic-ui-react";
const colors = [
  "red",
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
  "brown",
  "grey",
  "black",
];
export default function Avatar({
  name,
  isAdmin,
}: {
  name: string;
  isAdmin: boolean;
}) {
  let letter = name ? name[0] : "U";
  let index = letter.charCodeAt(0);

  return (
    <Label
      circular
      //   @ts-ignore
      color={colors[index % colors.length]}
      className="rounded-full overflow-hidden w-10 h-10 text-center flex items-center justify-center"
    >
      <span className="text-xl">{letter.toUpperCase()}</span>
    </Label>
  );
}
