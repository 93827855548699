import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  browserPopupRedirectResolver,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCG9oeBM3aa92_eCtsXx_vRN9IGafznU-I",
  authDomain: "monsterra-io.firebaseapp.com",
  projectId: "monsterra-io",
  storageBucket: "monsterra-io.appspot.com",
  messagingSenderId: "841978427062",
  appId: "1:841978427062:web:35d551c1629409c282d817",
  measurementId: "G-DXSLNZRZTZ",
};

// Initialize Firebase
let app = firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();

const auth = initializeAuth(app, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
});

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

googleProvider.setCustomParameters({ prompt: "select_account" });
facebookProvider.setCustomParameters({ display: "popup" });
facebookProvider.addScope("name");
facebookProvider.addScope("email");
facebookProvider.addScope("picture");

// export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);
// export const signInWithFacebook = () => auth.signInWithPopup(facebookProvider);

export const signInWithGoogle = async () => {
  let result: any = await signInWithPopup(auth, googleProvider, browserPopupRedirectResolver);
  return result;
};
export const signInWithFacebook = async () => {
  let result: any = await signInWithPopup(auth, facebookProvider, browserPopupRedirectResolver);
  return result;
};
