import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services";

export default function Redirect() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const requetsKey = query.get("requestKey");
  const userId = Number(query.get("userId"));
  const [status, setStatus] = useState<string>("Loging in...");
  useEffect(() => {
    async function requestToken() {
      let rs = await api.post("/customer/claim-token-request-key", {
        tokenRequestKey: requetsKey,
        userId,
        accessTokenType: 2,
      });
      const { supportToken, customerInfo } = rs;
      console.log({ supportToken, customerInfo });
      let userInfo = {
        id: customerInfo.A,
        name: customerInfo.D,
      };
      await api.setStateLogin(supportToken, userInfo);
      // @ts-ignore
      window.location = "/";
    }
    if (!requetsKey) {
      setStatus("Key not found");
    }
    requestToken();
  }, []);
  return (
    <div>
      <p className="text-center font-semibold text-xl">{status}</p>
    </div>
  );
}
