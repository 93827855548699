import { UploadImage } from "components";
import { TicketComment, TicketStatus, TicketSubject } from "interfaces";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Header,
  Icon,
  Label,
  Modal,
  Select,
  TextArea,
} from "semantic-ui-react";
import { api, ui, utils } from "services";
import Comment from "./comment";
export default function TicketDetail() {
  const StatusColors = ["red", "green"];
  const nav = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    async function loadTicket() {
      let rs = await api.post("/support-ticket/get-my-ticket", {
        id,
        skip: 0,
        limit: 1,
      });
      if (!rs?.rows[0]) {
        setData({});
        return ui.alertFailed("Ticket not found", "");
      }
      setData(rs?.rows[0]);
    }
    loadTicket();
  }, [id]);
  const [data, setData] = useState<any>({ id });
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<any>({});
  let subjectItems = useMemo(() => {
    let rs: any = [];
    for (var i in TicketSubject) {
      if (!isNaN(Number(i))) {
        rs.push({ key: i, value: Number(i), text: TicketSubject[i] });
      }
    }
    return rs;
  }, []);
  function handleChange(name: string, value: any) {
    let tmp = _.cloneDeep(comment);
    // @ts-ignore
    tmp[name] = value;
    setComment(tmp);
  }
  function onRemoveImage(index: number) {
    let tmp = _.cloneDeep(comment?.attachs || []);
    tmp.splice(index, 1);
    handleChange("attachs", tmp);
  }
  async function submitTicket() {
    try {
      setLoading(true);
      if (!comment.content) {
        setError("Please enter ticket content");
        return;
      }
      setError("");
      comment.id = id;
      let rs = await api.post(
        "/support-ticket/customer-comment-ticket",
        comment
      );
      setComment({});
      setData(rs);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }
  async function resolveTicket(status: TicketStatus) {
    let rs = await api.post("/support-ticket/customer-resolve-ticket", { id });
    setData(rs);
  }
  return (
    <div>
      <Modal open={openSuccess} size="small">
        <Modal.Content>
          <div className="text-center pb-10">
            <img src="img/success.png" className="w-28 h-28 mx-auto" />
            <p className="font-semibold text-4xl text-center mt-8">
              Submit success
            </p>
            <p className="text-center mt-8 text-xl">
              Your support ticket has been submitted successfully. Please wait
              for our response whithin 48 hours!
            </p>
            <button
              className="main-gradient p-2 w-48 rounded-md text-white mx-auto text-xl mt-16 block"
              onClick={() => {
                setOpenSuccess(false);
                nav(-1);
              }}
            >
              Close
            </button>
          </div>
        </Modal.Content>
      </Modal>
      <div>
        <span
          className="font-semibold text-xl cursor-pointer"
          onClick={() => {
            nav(-1);
          }}
        >
          <Icon name="angle left" /> Ticket detail
        </span>
      </div>
      <div className="rounded-md overflow-hidden mt-4">
        <div className="flex justify-between items-center py-3 px-6 bg-[#E2E3E8]">
          <p className="font-semibold text-xl">#{id}</p>
          <div className="flex gap-2 items-center">
            <p className="mr-4">
              <span className="mr-2">Status:</span>
              {/* @ts-ignore */}
              <Header as="span" color={StatusColors[data?.status]}>
                {TicketStatus[data?.status]}
              </Header>
            </p>
            {data.status !== TicketStatus.Resolved && (
              <button
                className="main-gradient py-2 px-4 rounded-md text-white"
                onClick={() => {
                  resolveTicket(TicketStatus.Resolved);
                }}
              >
                {loading ? "Loading..." : "Mark as Resolved"}
              </button>
            )}
          </div>
        </div>

        <div className="p-3 bg-white min-h-[450px]">
          <Comment comments={data?.comments || []} />
        </div>
        {data.status === TicketStatus.Unresolved && (
          <div className="p-3 bg-white">
            <div className="h-[1px] bg-[#DFE0E6] w-full mt-4"></div>
            <div>
              {comment?.attachs?.length > 0 && (
                <div className="flex flex-wrap gap-2 justify-end mt-4">
                  {comment?.attachs.map((url: string, index: number) => (
                    <div
                      key={index}
                      className="w-56 h-36 rounded-md border border-gray-200 p-2 relative"
                    >
                      <div
                        className="p-2 error-gradient rounded-md text-white w-10 h-10 text-center cursor-pointer absolute top-2 left-2"
                        onClick={() => {
                          onRemoveImage(index);
                        }}
                      >
                        <Icon className="trash" />
                      </div>
                      <img src={url} className="w-full h-full object-contain" />
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!utils.inWorkingTime() && (
              <p className="text-red-600">Working time: 2h UTC to 9h UTC.</p>
            )}
            <div className="flex gap-2 justify-between mt-4">
              <TextArea
                value={comment.content || ""}
                className="w-full border-[#DFE0E6] border rounded-md p-4"
                placeholder="Type here..."
                rows={2}
                onChange={(evt: any) => {
                  handleChange("content", evt.target.value);
                }}
              />
              <div className="flex justify-end">
                <UploadImage
                  setUploading={setUploading}
                  onChange={(url: any) => {
                    let tmp = _.cloneDeep(comment.attachs || []);
                    tmp.push(url);
                    handleChange("attachs", tmp);
                  }}
                >
                  <button
                    disabled={uploading}
                    className="bg-[#44240E] rounded-md py-2 px-4 text-white h-28"
                  >
                    {uploading ? "Uploading..." : "Attach file"}
                  </button>
                </UploadImage>
              </div>
            </div>
            {error !== "" && (
              <p className="text-red-600 font-semibold text-center mt-4">
                {error}
              </p>
            )}
            <div className="flex justify-center mt-4">
              <button
                className="main-gradient text-white rounded-md px-8 py-4"
                onClick={submitTicket}
              >
                {loading ? "Submiting..." : "Submit"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
