import "App.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "layout";
import ModalManager from "modal";
import { useEffect, useState } from "react";
import { api } from "services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { bulkSetUserInfo } from "reducer/user.slice";
import Account from "page/account/account";
function App() {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    api
      .getSSOMeta()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setTimeout(() => {
          setTrigger(!trigger);
        }, 5000);
      });
  }, [trigger]);

  async function loadUserInfo() {
    let token = sessionStorage.getItem("token");
    if (!token) {
      return;
    }
  }

  useEffect(() => {
    window.addEventListener("focus", loadUserInfo);
    return () => {
      window.removeEventListener("focus", loadUserInfo);
    };
  }, [user?.id]);

  if (isLoading) return <></>;

  return (
    <div className="App">
      <Router basename="/">
        <ModalManager />
        <Routes>
          <Route path="/account" element={<Account />}></Route>
          <Route path="/*" element={<Layout />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
