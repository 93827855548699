import Header from "./header";
import Main from "./main";

export default function Layout() {
  return (
    <div>
      <>
        <Header />
        <div className="main-layout">
          <div className="main-container">
            <Main />
          </div>
        </div>
      </>
    </div>
  );
}
