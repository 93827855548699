import { Menu, Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ui } from "services";
import { useState } from "react";
import { clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import { SSO_META } from "config";
import { PageComponent } from "components/page-component/page-component";
import { SingleSignon } from "components/singlesignon/singlesignon";
export default function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const nav = useNavigate();
  const [clickedLogout, setClickedLogout] = useState(false);

  async function onLogoutClick() {
    setClickedLogout(true);
    await ui.confirm(t("Are you sure want to logout?"), () =>
      setClickedLogout(false)
    );
    dispatch(clearUserInfo());
    dispatch(clearAuthInfos());
    sessionStorage.clear();
    localStorage.clear();
    nav("/");
  }

  return (
    <div className="w-full main-header bg-black-primary">
      <div className="">
        <Menu inverted className="main-container" borderless>
          <Menu.Item
            onClick={() => {
              nav("/");
            }}
            header
            className="logo-header"
          >
            <img src="/assets/images/logo.png" alt="logo" />
          </Menu.Item>
          <PageComponent SSO_META={SSO_META()} />

          <Menu.Menu inverted position="right">
            {user?.id && (
              <Menu.Item className="hoverless">
                <div className="rounded-lg border border-grey-primary overflow-hidden">
                  <Popup
                    position="bottom center"
                    style={{ padding: "unset", border: "unset" }}
                    hoverable
                    trigger={
                      <div className="text-center col-span-4 bg-black-linear-4 flex px-3 pt-0.5">
                        <div className="mt-3">Hello {user?.name}</div>
                        <img
                          src="/assets/images/icons/select-icon.svg"
                          alt="select"
                          className="ml-auto h-8 w-8 mt-2"
                        />
                      </div>
                    }
                    content={
                      clickedLogout ? (
                        <></>
                      ) : (
                        <>
                          <div className="max-w-full py-4 bg-black-linear-secondary"></div>
                          <span
                            className="flex bg-black-linear-3 py-4 px-10 text-white-light cursor-pointer hover-orange"
                            onClick={onLogoutClick}
                          >
                            <img
                              src="/assets/images/icons/logout-icon.svg"
                              alt="logout"
                              className="h-6 w-6 mr-2"
                            />{" "}
                            Logout
                          </span>
                        </>
                      )
                    }
                    basic
                  />
                </div>
              </Menu.Item>
            )}
            {!user?.id && (
              <Menu.Item>
                <span className="xl:hidden block">
                  <SingleSignon SSO_META={SSO_META()} type="signin" device="mobile" />
                </span>
                <span className="xl:block hidden">
                  <SingleSignon SSO_META={SSO_META()} type="signin" device="laptop" />
                </span>
              </Menu.Item>
            )}
          </Menu.Menu>
        </Menu>
      </div>
    </div>
  );
}
