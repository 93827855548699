import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import Home from "page/home";
import ScrollToTop from "./scrollToTop";
import ui from "../../src/services/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import YourTicket from "page/your-ticket";
import CreateTicket from "page/create-ticket";
import TicketDetail from "page/ticket-detail";
import Redirect from "page/redirect";

function RequireAuth({ children }: any) {
  const userInfo = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(null);
  useEffect(() => {
    if (!userInfo?.id) {
      setIsLogin(false);
      ui.alertFailed(t("must_login_first"), "");
    }
  }, []);

  if (userInfo?.id) {
    return children;
  } else if (isLogin === false) {
    return <Navigate to="/" />;
  } else {
    return <></>;
  }
}

export default function Main() {
  return (
    <div>
      <div className={`p-4 pt-5`}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route
              path="/your-ticket"
              element={
                <RequireAuth>
                  <YourTicket />
                </RequireAuth>
              }
            />
            <Route
              path="/create-ticket"
              element={
                <RequireAuth>
                  <CreateTicket />
                </RequireAuth>
              }
            />
            <Route
              path="/ticket-detail/:id"
              element={
                <RequireAuth>
                  <TicketDetail />
                </RequireAuth>
              }
            />
            <Route path="/redirect" element={<Redirect />} />
          </Routes>
        </ScrollToTop>
      </div>
    </div>
  );
}
