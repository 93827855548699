/**
 * eslint-disable jsx-a11y/alt-text
 *
 * @format
 */

/* eslint-disable react/jsx-no-target-blank */
import Config, { SSO_META } from "config";
import { UserRole } from "interfaces";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "reducer/store";
import { api } from "services";
import ui from "services/ui";

export default function Home() {
  const [star, setStar] = useState<number>(0);
  const [tmpStar, setTmpStar] = useState<number>(0);
  const user = useSelector((state: RootState) => state.user);
  const [feedback, setFeedback] = useState<string>("");
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  async function sendRate() {
    if (!user?.id) {
      return ui.alertFailed("You must login first", "");
    }
    if (!star || !feedback) {
      ui.alertFailed("Please enter star and feedback", "");
      return;
    }
    let rs = await api.post("/support-rate/create-rate", {
      star,
      feedback,
    });
    setStar(rs.star);
    setTmpStar(rs.star);
    setFeedback(rs.feedback);
    ui.alertSuccess("Thank you for your rate", "");
  }
  useEffect(() => {
    if (!user?.id) {
      return;
    }
    async function loadRate() {
      let rs = await api.post("/support-rate/get-my-rate", {});
      setTmpStar(rs?.star || 0);
      setStar(rs?.star || 0);
      setFeedback(rs?.feedback || "");
    }
    loadRate();
  }, [user?.id]);
  return (
    <div>
      <div className="container mx-auto pb-24">
        <h3 className="w-full text-center font-bold text-4xl mt-8">
          Monsterra Feedback and Support Center
        </h3>
        <div className="text-center w-96 mx-auto mt-6">
          <div className="bg-white rounded-md p-5 w-full mx-auto mt-4">
            If you are satisfied with the game let give us some rate stars
            <div id="rate" className="flex justify-center mt-2">
              {Array.from({ length: 5 }).map((e: any, index: number) => {
                if (index < tmpStar) {
                  return (
                    <div
                      className="px-2 inline cursor-pointer"
                      onMouseEnter={() => {
                        setTmpStar(index + 1);
                      }}
                      onMouseLeave={() => {
                        setTmpStar(star);
                      }}
                      onClick={() => {
                        setStar(index + 1);
                        setCanSubmit(true);
                      }}
                    >
                      <img src="img/star-fill.png" className="w-10 h-10" />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="px-2 inline cursor-pointer"
                      onMouseEnter={() => {
                        setTmpStar(index + 1);
                      }}
                      onMouseLeave={() => {
                        setTmpStar(star);
                      }}
                      onClick={() => {
                        setStar(index + 1);
                        setCanSubmit(true);
                      }}
                    >
                      <img src="img/star-empty.png" className="w-10 h-10" />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <p className="mt-8 text-left">
            If not please tell us how we can improve
          </p>
          <textarea
            onChange={(evt: any) => {
              setFeedback(evt.target.value);
              setCanSubmit(true);
            }}
            value={feedback}
            className="bg-white text-black w-full p-3 block mx-auto rounded-md border border-[#EBEBEB] ring-0 mt-4 placeholder-[#C4C4C8]"
            default-value=""
            placeholder="Please tell us your opinion!"
          ></textarea>
          <button
            onClick={() => {
              sendRate();
            }}
            className={`mx-auto ${
              canSubmit ? "main-gradient" : "bg-[#82837E]"
            } p-2 block text-white font-semibold rounded-md w-36 mt-4`}
          >
            Send
          </button>
          <div className="h-[1px] bg-[#DDDDDD] mt-4"></div>
          <p className="mt-4 text-left">Need direct support ?</p>
          {user?.role === UserRole.Customer ? (
            <Link to="/your-ticket">
              <div className="main-gradient flex justify-between text-white items-center rounded-md p-2 h-12 mt-4">
                <p>Keep track or create Support ticket</p>
                <img src="img/arrow-right.png" className="h-6" />
              </div>
            </Link>
          ) : (
            <div>
              <Link to="/admin-ticket">
                <div className="main-gradient flex justify-between text-white items-center rounded-md p-2 h-12 mt-4">
                  <p>Manage ticket</p>
                  <img src="img/arrow-right.png" className="h-6" />
                </div>
              </Link>
            </div>
          )}

          <div className="flex mt-2">
            <a
              href={SSO_META().WhitePaperLink}
              target="_blank"
              className="bg-white flex rounded-md items-center h-10 p-6 w-full mr-1"
            >
              <img src="img/whitepaper.png" className="h-8 mr-2" />
              <p className="text-[#EBC276]">Whitepaper</p>
            </a>
            <a
              href={SSO_META().GuideLink}
              target="_blank"
              className="bg-white flex rounded-md items-center h-10 p-6 w-full mr-1"
            >
              <img src="img/guideline.png" className="h-8 mr-2" />
              <p className="text-[#EBC276]">Guideline</p>
            </a>
          </div>
          <div className="w-full flex justify-center mt-8">
            <div className="px-1">
              <a href="https://twitter.com/Monsterra_P2E" target="_blank">
                <img src="img/twitter.png" className="w-10 h-10" />
              </a>
            </div>
            <div className="px-1">
              <a href="https://t.me/monsterra_official" target="_blank">
                <img src="img/telegram.png" className="w-10 h-10" />
              </a>
            </div>
            <div className="px-1">
              <a href="https://discord.com/invite/R5uG9WCyr7" target="_blank">
                <img src="img/discord.png" className="w-10 h-10" />
              </a>
            </div>
            <div className="px-1">
              <a
                href="https://www.facebook.com/MonsterraNFTGame/"
                target="_blank"
              >
                <img src="img/facebook.png" className="w-10 h-10" />
              </a>
            </div>
            <div className="px-1">
              <a href="https://medium.com/@monsterra.io" target="_blank">
                <img src="img/medium.png" className="w-10 h-10" />
              </a>
            </div>
            <div className="px-1">
              <a
                href="https://www.youtube.com/MonsterraNFTGame"
                target="_blank"
              >
                <img src="img/youtube.png" className="w-10 h-10" />
              </a>
            </div>
            <div className="px-1">
              <a
                href="https://www.tiktok.com/@monsterra_nftgame"
                target="_blank"
              >
                <img src="img/tiktok.png" className="w-10 h-10" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
